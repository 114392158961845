import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Suite} from 'app/fragment/suite';
import {Template, TemplateService} from 'app/services/template.service';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {RoleService} from 'app/services/user/role.service';
import {User} from 'app/user/user';
import {LocalConfigUtils} from 'app/utils/local-config-utils';
import {Search} from 'app/utils/search';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {fromEvent, merge as mergeStatic, Observable, ReplaySubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {DocumentService} from '../../../services/document.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'cars-document-creator',
  templateUrl: './document-creator.component.html',
  styleUrls: ['./document-creator.component.scss'],
})
export class DocumentCreatorComponent implements OnInit {
  @Output() public created: EventEmitter<UUID> = new EventEmitter<UUID>();
  @Output() public canceled: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('title', {static: true}) public titleInput: ElementRef;
  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSelect, {static: true}) public matSelect: MatSelect;
  @ViewChild(MatSort, {static: true}) public sort: MatSort;

  public newTitle: string;

  public suites: string[] = [];
  public selectedSuite: Suite;

  public templateDataSource: TemplateDataSource;
  public displayedColumns: string[] = ['suite', 'name', 'owner'];
  public noTemplate: boolean = false;
  public selectedTemplate: Template;

  public submitted: boolean = false;

  public suiteErrorMessage: string = 'Suite must be selected';

  public tooltipDelay: number = environment.tooltipDelay;

  // A map from each suite to the global role required to create a new document of that suite.
  // Note this defines the order in which suites are displayed.
  private readonly _suiteToRequiredGlobalRoleMap: Readonly<Record<Suite, GlobalRole>> = {
    DMRB: GlobalRole.DMRB_CREATOR,
    LEGACY_DMRB: GlobalRole.DMRB_IMPORTER,
    LEGACY_MCHW: GlobalRole.MCHW_IMPORTER,
    MCHW: GlobalRole.MCHW_CREATOR,
    MCHW_GENERAL: GlobalRole.MCHW_GENERAL_CREATOR,
    MOMHW: GlobalRole.METHOD_OF_MEASUREMENT_CREATOR,
    UNRESTRICTED: GlobalRole.UNRESTRICTED_CREATOR,
  };

  constructor(
    private documentService: DocumentService,
    private userService: UserService,
    private templateService: TemplateService,
    private roleService: RoleService
  ) {}

  /**
   * Initialise this component.
   */
  public ngOnInit(): void {
    this.templateDataSource = new TemplateDataSource(
      this.templateService,
      this.userService,
      this.sort,
      this.filter,
      this.matSelect
    );
    this.titleInput.nativeElement.focus();
    this._setSuites();
  }

  private _setSuites(): void {
    this.suites = Object.keys(this._suiteToRequiredGlobalRoleMap).filter((suite: Suite) =>
      this.roleService.isInGlobalRoles(this._suiteToRequiredGlobalRoleMap[suite])
    );
  }

  /**
   * Create the new document by calling through to the DocumentService.
   */
  public onSubmit(): void {
    if (this.selectedTemplate) {
      this.documentService
        .createDocumentFromTemplate(this.selectedTemplate, this.newTitle)
        .then((id: UUID) => this.created.emit(id));
    } else {
      this.documentService.createDocument(this.newTitle, this.selectedSuite).then((id: UUID) => this.created.emit(id));
    }
    this.submitted = true;
  }

  public onSuiteChange(): void {
    this.noTemplate = false;
    this.selectedTemplate = null;
  }

  /**
   * Sets the selected template to the given argument.
   * Un-sets the selected template if given the same one.
   *
   * @param template {Template} Template to set
   */
  public rowClick(template: Template): void {
    this.noTemplate = false;
    this.selectedTemplate =
      this.selectedTemplate && this.selectedTemplate.versionId === template.versionId ? null : template;
  }
}

export class TemplateDataSource extends DataSource<Template> {
  private currentPage: ReplaySubject<Template[]> = new ReplaySubject(1);

  public allTemplates: Template[] = [];
  public color: string = 'primary';
  public loading: boolean = true;
  public userHasRoleOnly: boolean = true;
  public filteredSize: number = 0;

  private subscriptions: Subscription[] = [];

  public pageOptions = {
    pageIndex: 0,
    pageSize: LocalConfigUtils.getConfig().documentPageSize,
    filter: '',
    suite: null,
    sizeOptions: [5, 10, 15, 25],
  };

  constructor(
    private templateService: TemplateService,
    private userService: UserService,
    private sort: MatSort,
    private filterElement: ElementRef,
    private matSelect: MatSelect
  ) {
    super();

    const filterChange = fromEvent(this.filterElement.nativeElement, 'keyup').pipe(
      debounceTime(250),
      distinctUntilChanged(),
      tap(() => {
        this.pageOptions.filter = this.filterElement.nativeElement.value.toLowerCase();
        this.pageOptions.pageIndex = 0;
      })
    );

    const suiteChange: Observable<Suite> = this.matSelect.valueChange.pipe(
      tap((suite: Suite) => {
        this.pageOptions.suite = suite;
        this.pageOptions.pageIndex = 0;
      })
    );

    mergeStatic(sort.sortChange, filterChange, suiteChange).subscribe(() => {
      this.updateTable();
    });

    this.getTemplates();
  }

  /**
   * Updates the table by applying relevant filters, sorting & paging.
   * Broadcasts filtered templates.
   */
  private updateTable(): void {
    let templates = this.allTemplates;

    templates = this.filterTemplates(templates);
    this.filteredSize = templates.length;

    templates = this.sortTemplates(templates);
    templates = this.pageTemplates(templates);

    this.currentPage.next(templates);
  }

  /**
   * Fetches templates from the web-service and attaches the created by name onto template.
   */
  private getTemplates(): void {
    this.loading = true;
    this.color = 'primary';

    this.templateService
      .getTemplates()
      .toPromise()
      .then((templates: Template[]) => {
        this.loading = false;

        templates.forEach((template: Template) => this.attachName(template));

        this.allTemplates = templates;

        this.updateTable();
      })
      .catch((e: any) => {
        this.color = 'warn';
        Logger.error('document-templates-error', 'Failed to fetch templates', e);
        setTimeout(() => this.getTemplates(), 3000);
      });
  }

  /**
   * Attaches the created by user to the template.
   *
   * @param template {Template}   Template to attach name to
   */
  private attachName(template: Template): void {
    this.subscriptions.push(
      this.userService.getUserFromId(UUID.orNull(template.createdBy)).subscribe((owner: User) => {
        template['createdByUser'] = owner;
      })
    );
  }

  /**
   * @inheritdoc
   */
  public connect(collectionViewer: CollectionViewer): Observable<Template[]> {
    return this.currentPage;
  }

  /**
   * @inheritdoc
   */
  public disconnect(collectionViewer: CollectionViewer): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Event emitted when the paginator changes the page size or page index.
   */
  public pageEvent(event): void {
    Object.assign(this.pageOptions, event);
    LocalConfigUtils.setDocumentPageSize(this.pageOptions.pageSize);
    this.updateTable();
  }

  /**
   * Sorts the templates based on active sort value and direction.
   *
   * @param templates {Template[]}   Templates to sort
   * @returns         {Template[]}   Sorted templates
   */
  private sortTemplates(templates: Template[]): Template[] {
    return templates.sort((a: Template, b: Template) => {
      const field: string = this.sort.direction === '' ? 'value' : this.sort.active;
      const dir: number = this.sort.direction === 'desc' ? -1 : 1;

      return a[field] === b[field] ? 0 : a[field] > b[field] ? dir : -dir;
    });
  }

  /**
   * Pages templates based on selected page size and page index.
   *
   * @param templates {Template[]}   Templates to page
   * @returns         {Template[]}   Paged templates
   */
  private pageTemplates(templates: Template[]): Template[] {
    const start: number = this.pageOptions.pageIndex * this.pageOptions.pageSize;
    const end: number = start + this.pageOptions.pageSize;
    return templates.slice(start, end);
  }

  /**
   * Filters the given templates based on the applied filters.
   *
   * @param templates {Template[]}   Templates to filter
   * @returns         {Template[]}   Filtered templates
   */
  private filterTemplates(templates: Template[]): Template[] {
    return templates
      .filter((template: Template) => this.naaFilter(template))
      .filter((template: Template) => this.searchFilter(template));
  }

  /**
   * Filter out NAA templates unless user is an Admin
   *
   * @param template {Template}   Template to check
   * @returns        {boolean}    True if template should be displayed in the table
   */
  private naaFilter(template: Template): boolean {
    return this.userService.isAdmin() || !template.forNaa;
  }

  /**
   * Checks if the template should be displayed against the input filter from the table
   * based on string matching.
   *
   * @param template {Template}   Template to check
   * @returns        {boolean}    True if template should be displayed in the table
   */
  private searchFilter(template: Template): boolean {
    const filter: string = this.pageOptions.filter;
    const toCheck: string[] = [template.name];
    if (template['createdByUser']) {
      toCheck.push(template['createdByUser'].name);
    }

    const suite: Suite = this.pageOptions.suite;

    return Search.check(filter, ...toCheck) && (suite === null || template.suite === suite);
  }
}
