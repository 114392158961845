import {NgModule} from '@angular/core';
import {CategoryOfChangeToDisplayNamePipe} from './category-of-change-to-display-name.pipe';
import {DocInfoValueToDisplayNamePipe} from './doc-info-value-to-display-name.pipe';
import {DocumentChangesToDisplayNamePipe} from './document-changes-to-display-name.pipe';
import {SuiteToDisplayNamePipe} from './suite-to-display-name.pipe';
import {TypeOfDocumentToDisplayNamePipe} from './type-of-document-to-display-name.pipe';

@NgModule({
  declarations: [DocInfoValueToDisplayNamePipe],
  providers: [
    SuiteToDisplayNamePipe,
    CategoryOfChangeToDisplayNamePipe,
    DocumentChangesToDisplayNamePipe,
    TypeOfDocumentToDisplayNamePipe,
  ],
  exports: [DocInfoValueToDisplayNamePipe],
})
export class DocInfoValueToDisplayNameModule {}
