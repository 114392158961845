import {Pipe, PipeTransform} from '@angular/core';
import {MchwDocumentType} from 'app/fragment/types';

export const TYPE_OF_DOCUMENT: Readonly<Record<MchwDocumentType, string>> = {
  [MchwDocumentType.IFS]: 'Instructions for Specifiers (IfS)',
  [MchwDocumentType.SHW]: 'Specification for Highway Works (SHW)',
};

@Pipe({
  name: 'typeOfDocumentToDisplayName',
})
export class TypeOfDocumentToDisplayNamePipe implements PipeTransform {
  transform(typeOfDocument: string): string {
    return TYPE_OF_DOCUMENT[typeOfDocument] ?? '';
  }
}
