import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {TreeDiffer} from 'app/fragment/tree-differ';
import {ClauseFragment, DocumentFragment, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from 'app/fragment/types/reference/internal-inline-reference-fragment';
import {ChangeSummaryRow, SectionChangeSummaryRow} from './types/change-summary-row';
import {ChangeSummaryRowType} from './types/change-summary-row-type';

type FragmentMap = Map<string, Fragment>;

export class ClauseChangeSummaryDiffUtils {
  /**
   * Diff change summary row to represent new fragments as diffs from old fragments.
   *
   * @param changeSummaryRow {SectionChangeSummaryRow} The section change summary row to diff.
   */
  public static diffChangeSummaryRow(
    changeSummaryRow: SectionChangeSummaryRow,
    previousDocument: DocumentFragment,
    currentDocument: DocumentFragment
  ) {
    // Create the diff'ed fragment tree
    const diffedFragment: SectionFragment = new TreeDiffer().diffWithReferences(
      this._prepFragmentForCompare(changeSummaryRow.oldFragment, changeSummaryRow.newFragment),
      this._prepFragmentForCompare(changeSummaryRow.newFragment, changeSummaryRow.oldFragment),
      previousDocument,
      currentDocument
    ) as SectionFragment;
    // Map each fragment id to its fragment
    const fragmentMap: FragmentMap = new Map();
    diffedFragment.iterateDown(null, null, (fragment: Fragment) => {
      if (fragment.type === FragmentType.FIGURE) {
        fragment['landscape'] = false;
      }
      fragmentMap.set(fragment.id.value, fragment);
    });
    // Replace the new fragment in change summary row (and descendents) with its diff'ed equivalent
    this._updateNewFragmentWithDiffs(changeSummaryRow, fragmentMap);

    const previousDocumentReferenceFragments = this._createReferenceLookup(previousDocument);

    changeSummaryRow.childRows
      .filter((childSummaryRow) => !!childSummaryRow.oldFragment)
      .forEach((childSummaryRow) => {
        childSummaryRow.oldFragment.iterateDown(null, null, (frag: Fragment) => {
          if (frag.is(FragmentType.INTERNAL_INLINE_REFERENCE)) {
            const refFrag = frag as InternalInlineReferenceFragment;
            refFrag.diffedInternalDocumentReference = previousDocumentReferenceFragments.get(
              refFrag.internalDocumentReferenceId.value
            );
          }
        });
      });
  }

  private static _createReferenceLookup(root: DocumentFragment): Map<string, InternalDocumentReferenceFragment> {
    const lookup: Map<string, InternalDocumentReferenceFragment> = new Map();
    if (!root) {
      return lookup;
    }
    root.getReferenceSections().forEach((section) =>
      section.iterateDown(null, null, (fragment: Fragment) => {
        if (fragment?.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
          lookup.set(fragment.id.value, fragment as InternalDocumentReferenceFragment);
        }
      })
    );
    return lookup;
  }

  /**
   * Helper to prepare fragment for TreeDiffer comparison.
   *
   * @param fragment            {SectionFragment} the fragment to prepare for comparison (old or new)
   * @param counterpartFragment {SectionFragment} the counterpart of the fragment being prepared for comparison.
   *                                              i.e. the new or old fragment.
   * @returns                   {SectionFragment} either, a deep clone of the fragment if it exists,
   *                                              or, a dummy fragment with same root and section type as counterpart.
   */
  private static _prepFragmentForCompare(
    fragment: SectionFragment,
    counterpartFragment: SectionFragment
  ): SectionFragment {
    return fragment
      ? (FragmentMapper.deepClone(fragment) as SectionFragment)
      : new SectionFragment(
          counterpartFragment.id,
          '',
          counterpartFragment.sectionType,
          [],
          true,
          'subject',
          'topic',
          'wsr code'
        );
  }

  /**
   * Replace the raw new fragment with its diff'ed (vs old) counterpart on specified change summary row and descendents.
   *
   * @param changeSummaryRow {ChangeSummaryRow} change summary row to amend with diff'ed fragments
   * @param fragmentMap      {FragmentMap}      the map of fragment ids to their diff'ed fragments
   */
  private static _updateNewFragmentWithDiffs(changeSummaryRow: ChangeSummaryRow, fragmentMap: FragmentMap) {
    const fragmentId = changeSummaryRow.newFragment
      ? changeSummaryRow.newFragment.id.value
      : changeSummaryRow.oldFragment.id.value; // Fragment deleted with respect to new
    if (fragmentId) {
      const diffedFragment = fragmentMap.get(fragmentId);
      switch (changeSummaryRow.rowType) {
        case ChangeSummaryRowType.SECTION:
          changeSummaryRow.newFragment = diffedFragment as SectionFragment;
          break;
        case ChangeSummaryRowType.CLAUSE:
        case ChangeSummaryRowType.HEADING:
          changeSummaryRow.newFragment = diffedFragment as ClauseFragment;
          break;
      }
      changeSummaryRow.childRows.forEach((childRow: ChangeSummaryRow) =>
        this._updateNewFragmentWithDiffs(childRow, fragmentMap)
      );
    }
  }
}
