import {Component, Inject, OnInit} from '@angular/core';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment, DocumentInformationType, SectionFragment, SectionType} from 'app/fragment/types';
import {VersionTagType} from 'app/fragment/versioning/version-tag-type';
import {CarsNotification, NotificationStatus} from 'app/header/notification-area/notification/cars-notification';
import {VersionRequest} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {NotificationService} from 'app/services/notification.service';
import {ConfigurationService, DocumentInformationField, FieldType} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {ValidationError, ValidationRule} from 'app/validation/validation-rule';
import {ValidationService} from 'app/validation/validation.service';
import {first} from 'rxjs/operators';
import {VersioningService} from '../../../../../fragment/versioning/versioning.service';

@Component({
  selector: 'cars-version-creator',
  templateUrl: './version-creator.component.html',
  styleUrls: ['./version-creator.component.scss'],
})
export class VersionCreatorComponent implements OnInit {
  private static readonly VERSION_TAG_TYPES_WITH_PUBLICATION: Readonly<VersionTagType[]> = [
    VersionTagType.DRAFT_FOR_PEER_REVIEW,
    VersionTagType.DRAFT_FOR_CONSULTATION,
    VersionTagType.FOR_PUBLICATION,
  ];

  private static readonly VERSION_TAG_TYPES_WITHOUT_PUBLICATION: Readonly<VersionTagType[]> = [
    VersionTagType.DRAFT_FOR_PEER_REVIEW,
    VersionTagType.DRAFT_FOR_CONSULTATION,
  ];

  // Suites without entries in this map cannot have for approval versions created and that option doesn't appear in the dropdown
  private static readonly SUITE_TO_VALIDATION_MAP: Readonly<Record<Suite, Readonly<DocumentInformationType[]>>> = {
    [Suite.DMRB]: [
      DocumentInformationType.CATEGORY_OF_CHANGE,
      DocumentInformationType.DOCUMENT_CHANGES,
      DocumentInformationType.REVISION_RELEASE_NOTES,
      DocumentInformationType.SUMMARY,
      DocumentInformationType.DOCUMENT_CODE,
      DocumentInformationType.DISCIPLINE,
      DocumentInformationType.LIFECYCLE_STAGE,
    ],
    [Suite.MCHW_GENERAL]: [
      DocumentInformationType.CATEGORY_OF_CHANGE,
      DocumentInformationType.DOCUMENT_CHANGES,
      DocumentInformationType.REVISION_RELEASE_NOTES,
      DocumentInformationType.SUMMARY,
      DocumentInformationType.DOCUMENT_CODE,
      DocumentInformationType.DISCIPLINE,
      DocumentInformationType.LIFECYCLE_STAGE,
    ],
    [Suite.MCHW]: [
      DocumentInformationType.CATEGORY_OF_CHANGE,
      DocumentInformationType.DOCUMENT_CHANGES,
      DocumentInformationType.RELEASE_NOTES_IFS,
      DocumentInformationType.RELEASE_NOTES_SHW,
      DocumentInformationType.DISCIPLINE,
      DocumentInformationType.DOCUMENT_NUMBER,
    ],
    [Suite.MOMHW]: [
      DocumentInformationType.CATEGORY_OF_CHANGE,
      DocumentInformationType.REVISION_RELEASE_NOTES,
      DocumentInformationType.SUMMARY,
      DocumentInformationType.DOCUMENT_NUMBER,
      DocumentInformationType.DISCIPLINE,
      DocumentInformationType.LIFECYCLE_STAGE,
      DocumentInformationType.DOCUMENT_TITLE,
      DocumentInformationType.EXPORT_SUITE_DISPLAY_NAME,
      DocumentInformationType.EXPORT_FRONT_PAGE_FOOTER,
      DocumentInformationType.EXPORT_BACK_PAGE_FOOTER,
    ],
    [Suite.UNRESTRICTED]: [
      DocumentInformationType.CATEGORY_OF_CHANGE,
      DocumentInformationType.REVISION_RELEASE_NOTES,
      DocumentInformationType.DOCUMENT_CODE,
    ],
    [Suite.LEGACY_DMRB]: [],
    [Suite.LEGACY_MCHW]: [],
  };

  public availableVersionTypeList: Readonly<VersionTagType[]> = [];

  private _document: DocumentFragment;
  private _isUnlinkedNaa: boolean = false;
  public template: boolean = false;

  private _selectedVersionType: VersionTagType = null;
  public isValidPublication: boolean = true;
  public publishingUnlinkedNaa: boolean = false;
  public showWsrMappingErrorMessage: boolean = false;
  public hasBlockingValidationErrors: boolean = false;

  public name: string = '';

  public availableToReview: boolean = false;
  public availableForCommenting: boolean = false;
  public userDiscussionsOnly: boolean = false;

  public loading: boolean = true;
  public creating: boolean = false;
  public versionInProgress: boolean = false;
  public validationLoading: boolean = false;

  public documentInformationFieldsToValidate: Readonly<DocumentInformationField[]>;

  constructor(
    private _versioningService: VersioningService,
    private _notificationService: NotificationService,
    private _configurationService: ConfigurationService,
    private _documentService: DocumentService,
    private _validationService: ValidationService,
    public dialogRef: MatDialogRef<VersionCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {document: DocumentFragment; template: boolean}
  ) {
    this._document = data.document;
    this.template = data.template;
    this._selectedVersionType = this.template ? VersionTagType.TEMPLATE : null;
  }

  public ngOnInit(): void {
    Promise.all([
      this._versioningService.isVersionCreationInProgress(this._document.id),
      this._configurationService.getDocumentInformationConfigurationForSuite(this._document.suite),
      this._isDocumentUnlinkedNaa(),
    ]).then(
      ([versionInProgress, documentConfig, isDocumentUnlinkedNaa]: [boolean, DocumentInformationField[], boolean]) => {
        this.versionInProgress = versionInProgress;
        this._isUnlinkedNaa = isDocumentUnlinkedNaa;

        const requiredDocumentInformation: Readonly<DocumentInformationType[]> =
          VersionCreatorComponent.SUITE_TO_VALIDATION_MAP[this._document.suite];

        if (!!requiredDocumentInformation.length) {
          this.availableVersionTypeList = VersionCreatorComponent.VERSION_TAG_TYPES_WITH_PUBLICATION;
          this.documentInformationFieldsToValidate = documentConfig.filter(
            (documentField: DocumentInformationField) =>
              documentField.documentInformationType &&
              requiredDocumentInformation?.includes(documentField.documentInformationType)
          );
        } else {
          this.availableVersionTypeList = VersionCreatorComponent.VERSION_TAG_TYPES_WITHOUT_PUBLICATION;
        }

        this.loading = false;
      }
    );
  }

  /**
   * Create a new version of the currently selected document
   */
  public create(): void {
    this.creating = true;

    const versionRequest: VersionRequest = {
      fragmentId: this._document.id.value,
      name: this.name,
      availableToReview: this.availableToReview,
      availableForCommenting: this.availableForCommenting,
      userDiscussionsOnly: this.userDiscussionsOnly,
      versionTagType: this._selectedVersionType,
      shouldCreateTag: true,
    };

    this._versioningService
      .createDocumentVersion(versionRequest)
      .then((notificationId: UUID) =>
        this._notificationService
          .onNotificationList()
          .pipe(
            first((notifications: CarsNotification[]) =>
              notifications.some(
                (notification: CarsNotification) =>
                  notification.notificationId.equals(notificationId) &&
                  notification.status === NotificationStatus.COMPLETED
              )
            )
          )
          .subscribe(() => this.dialogRef.close())
      )
      .catch(() => (this.creating = false));
  }

  public onVersionTypeSelect(event: MatOptionSelectionChange, selectedVersionTagType: VersionTagType) {
    if (event.isUserInput) {
      this._selectedVersionType = selectedVersionTagType;
      this.isValidPublication =
        this._selectedVersionType !== VersionTagType.FOR_PUBLICATION || this._areFieldsRequiredForPublicationSet();
      this.publishingUnlinkedNaa = this._selectedVersionType === VersionTagType.FOR_PUBLICATION && this._isUnlinkedNaa;
      this.showWsrMappingErrorMessage =
        this._selectedVersionType === VersionTagType.FOR_PUBLICATION &&
        this._document.isSuite(Suite.MCHW) &&
        !this._areWSRMappingFieldsSetForAllSections();
      this.validationLoading = true;
      this._containsBlockingErrors(selectedVersionTagType).then((hasBlocks) => {
        this.hasBlockingValidationErrors = hasBlocks;
        this.validationLoading = false;
      });
    }
  }

  private async _isDocumentUnlinkedNaa(): Promise<boolean> {
    const parentDocumentId = this._document.documentData.coreDocument;

    // if we aren't a DMRB document, or are a core DMRB document then we aren't an unlinkedNaa
    if (this._document.suite !== Suite.DMRB || !parentDocumentId) {
      return false;
    }

    return this._documentService.fetchLatest(parentDocumentId, {depth: 0}).then((parentDocument: DocumentFragment) => {
      return !(parentDocument.documentData.naaRelationships && parentDocument.documentData.naaRelationships.enabled);
    });
  }

  /**
   * Whether or not a form field should be disabled.
   */
  public fieldDisabled(): boolean {
    return this.loading || this.creating || this.versionInProgress || this.validationLoading;
  }

  /**
   * Whether or not the create button should be disabled.
   */
  public creationDisabled(): boolean {
    return (
      !this.isValidPublication ||
      this.showWsrMappingErrorMessage ||
      this.publishingUnlinkedNaa ||
      this.name.replace(/\s/g, '').length <= 0 ||
      !this._selectedVersionType ||
      this.fieldDisabled() ||
      this.hasBlockingValidationErrors
    );
  }

  /**
   * Responds to changes to the document being available for review.
   */
  public changeAvailableToReview(): void {
    this.availableForCommenting = false;
    this.userDiscussionsOnly = false;
  }

  /**
   * Responds to changes to the document being available for commenting.
   */
  public changeAvailableForCommenting(): void {
    this.availableToReview = true;
    this.userDiscussionsOnly = false;
  }

  /**
   * Responds to changes to the document displaying only discussions raised by the user.
   */
  public changeUserDiscussionsOnly(): void {
    this.availableToReview = true;
    this.availableForCommenting = true;
  }

  private _areFieldsRequiredForPublicationSet(): boolean {
    return this.documentInformationFieldsToValidate?.every(this._isDocumentFieldSet.bind(this));
  }

  private _isDocumentFieldSet(documentInformationField: DocumentInformationField): boolean {
    const documentInformationType: DocumentInformationType = documentInformationField.documentInformationType;

    switch (documentInformationField.fieldType) {
      case FieldType.FREE_TEXT:
      case FieldType.FREE_TEXT_MULTILINE:
        const text: string = this._document.getInformation(documentInformationType)?.value;
        return text && text.trim().length > 0;
      case FieldType.ENUM:
        return !!this._document.getInformation(documentInformationType)?.value;
      case FieldType.MULTI_SELECT:
        const values: any[] = this._document.getInformation(documentInformationType)?.values;
        return values && values.length > 0;
      case FieldType.NON_EDITABLE:
        return true;
      case FieldType.IMAGE_UPLOAD:
        return this._document.getInformation(documentInformationType)?.children.length > 0;
    }
  }

  private _areWSRMappingFieldsSetForAllSections(): boolean {
    const sectionsWithMissingFields: SectionFragment[] = this._document
      .getSections()
      .filter(
        (section: SectionFragment) =>
          (!section.subject || !section.topic || !section.wsrCode) &&
          !section.deleted &&
          !section.isSectionOfType(
            SectionType.DOCUMENT_INFORMATION,
            SectionType.REFERENCE_NORM,
            SectionType.REFERENCE_INFORM
          )
      );

    if (sectionsWithMissingFields.length > 0) {
      this.showWsrMappingErrorMessage = true;
      return false;
    }

    return true;
  }

  private async _containsBlockingErrors(versionTagType: VersionTagType): Promise<boolean> {
    const filteredRules: ValidationRule[] = this._validationService.filterValidationRulesForVersionType(
      versionTagType,
      this._document.suite
    );
    if (filteredRules.length === 0) {
      return false;
    }

    const document: DocumentFragment = await this._documentService.load(
      this._document.id,
      {projection: 'FULL_TREE'},
      false
    );

    const blockingErrors: ValidationError[] = this._validationService.validateDocumentForVersionCreation(
      document,
      filteredRules
    );
    return blockingErrors.length > 0;
  }
}
