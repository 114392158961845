import {HttpRequest} from '@angular/common/http';
import {User} from 'app/user/user';
import {UUID} from 'app/utils/uuid';
import {Observable} from 'rxjs';

/**
 * A subset of the global roles we have in keycloak.
 * Should mirror the roles in keycloak.
 */
export enum GlobalRole {
  ADMIN = 'admin', // Allows a user to view stats page and user management.
  DELETE_DOCUMENTS = 'delete_documents', // Allows a user to delete documents.
  DOCUMENT_ADMIN = 'document_admin', // Allows a user to view all documents and change the document owner.
  DMRB_CREATOR = 'dmrb_creator', // Allows a user to create a new DMRB document.
  DMRB_IMPORTER = 'dmrb_importer', // Allows a user to create an imported DMRB document.
  MCHW_CREATOR = 'mchw_creator', // Allows a user to create a new MCHW document.
  MCHW_IMPORTER = 'mchw_importer', // Allows a user to create an imported MCHW document.
  MCHW_GENERAL_CREATOR = 'mchw_general_creator', // Allows a user to create a new MCHW General document
  METHOD_OF_MEASUREMENT_CREATOR = 'method_of_measurement_creator', // Allows a user to create a new MOMHW document.
  UNRESTRICTED_CREATOR = 'unrestricted_creator', // Allows a user to create a new unrestricted document.
  OFFLINE_MODE = 'cars_offline', // Allows a user to access offline mode.
  USER_CREATOR = 'user_creator', // Allows a user to create a new user account within CARS.
  CHANGELOG = 'changelog', // Allows the user to use the changelog.
  TEMPLATE_CREATOR = 'template_creator', // Allows user to create a template from a document.
  VIEW_PUBLISHED = 'view_published_documents', // Allows users to see all imported documents
  VIEW_PDF_PREVIEW = 'view_pdf_preview', // Allows users to open and use the pdf preview
  DOCUMENT_EXPORTER = 'document_exporter', // Allows users to generate PDF and HTML documents with or without NAAs,
  MCHW_EXPORTER = 'mchw_exporter', // Allows users to generate mchw documents in PDF or HTML format
  MCHW_GENERAL_EXPORTER = 'mchw_general_exporter', // Allows users to generate MCHW General documents
  WSR_MAPPING_EDITOR = 'wsr_mapping_editor', // Allows user to edit WSR Subject Topic Mapping table,
  DOCUMENT_REVERTER = 'document_reverter',
}

export abstract class AuthenticationProvider {
  public static UNKNOWN_USER: User = User.unknown();

  public static userFromkeycloakUser(token: any): User {
    return User.deserialise({
      id: token.sub,
      userName: token.preferred_username,
      name:
        token.family_name && token.given_name ? token.given_name + ' ' + token.family_name : token.preferred_username,
      email: token.email,
      organisation: token.organisation,
      initials:
        token.family_name && token.given_name
          ? token.given_name.charAt(0) + token.family_name.charAt(0)
          : token.preferred_username.substring(0, 2),
    });
  }

  /**
   * @returns An observable that will fire once all authentication initilisation has completed.
   */
  abstract isAuthenticated(): Observable<boolean>;

  /**
   * @returns {Observable<User>}   Observable stream of the current user.
   */
  abstract getCurrentUserObs(): Observable<User>;

  /**
   * @returns A User object representing the currently logged in user.
   */
  abstract getCurrentUser(): User;

  /**
   * @returns    {GlobalRole[]} The global roles assigned to the current user
   */
  abstract getCurrentGlobalRoles(): Observable<GlobalRole[]>;

  /**
   * Returns the URL to the Account Management page.
   */
  abstract createAccountUrl(): string;

  /**
   * Returns the URL to the logout page.
   */
  abstract createLogoutUrl(): string;

  /**
   * Returns the URL to the user management page.
   */
  abstract userManagement(): string;

  /**
   * Convenience method to tell if a user is in the admin role.
   */
  abstract isAdmin(): boolean;

  /**
   * @param id    {UUID}                The Id of the user account to investigate.
   * @returns     {Observable<User>}      A User object for the account with the given Id.
   */
  abstract getUser(id: UUID): Observable<User>;

  /**
   * @param request   {HttpRequest<any>}    The request to augment with authentication
   * @returns         {HttpRequest<any>}    A request that has had authentication headers or paramaters added
   */
  abstract augmentRequest(request: HttpRequest<any>): Observable<HttpRequest<any>>;

  /**
   * Determine if the current user has any of the specified roles.
   *
   * @param roles The roles to check.
   */
  abstract userInRoles(...roles: GlobalRole[]): boolean;

  /**
   * @returns    {boolean}   Whether this provider provides online authentication functionality
   */
  abstract offline(): boolean;

  /**
   * @returns    {string}    The URL of the authentication provider.
   */
  abstract getBaseUrl(): string;
}
