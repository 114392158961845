import {Pipe, PipeTransform} from '@angular/core';
import {Suite} from 'app/fragment/suite';
import {CategoryOfChange} from 'app/fragment/types/category-of-change';
import {DocInfoValueDisplayNameType} from 'app/suite-config/configuration.service';
import {CategoryOfChangeToDisplayNamePipe} from './category-of-change-to-display-name.pipe';
import {DocumentChangesToDisplayNamePipe} from './document-changes-to-display-name.pipe';
import {SuiteToDisplayNamePipe} from './suite-to-display-name.pipe';
import {TypeOfDocumentToDisplayNamePipe} from './type-of-document-to-display-name.pipe';

@Pipe({
  name: 'docInfoValueDisplayName',
})
export class DocInfoValueToDisplayNamePipe implements PipeTransform {
  constructor(
    private _suiteDisplayPipe: SuiteToDisplayNamePipe,
    private _categoryOfChangeDisplayPipe: CategoryOfChangeToDisplayNamePipe,
    private _documentChangesDisplayPipe: DocumentChangesToDisplayNamePipe,
    private _typeOfDocumentDisplayPipe: TypeOfDocumentToDisplayNamePipe
  ) {}

  /**
   * Converts a value to a display name based on the document information valueDisplayName.
   */
  public transform(value: string, valueDisplayName?: DocInfoValueDisplayNameType): string {
    switch (valueDisplayName) {
      case DocInfoValueDisplayNameType.SUITE:
        return this._suiteDisplayPipe.transform(value as Suite);
      case DocInfoValueDisplayNameType.CATEGORY_OF_CHANGE:
        return this._categoryOfChangeDisplayPipe.transform(value as CategoryOfChange);
      case DocInfoValueDisplayNameType.DOCUMENT_CHANGES:
        return this._documentChangesDisplayPipe.transform(value);
      case DocInfoValueDisplayNameType.TYPE_OF_DOCUMENT:
        return this._typeOfDocumentDisplayPipe.transform(value);
      default:
        return value;
    }
  }
}
